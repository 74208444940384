#root {
    /* サインアップ画面 */
    main.signup {
        display: flex;
        justify-content: center;
        align-items: center;

        div.signup-box {
            padding: 3rem;
            border : var(--line-base);
            border-radius: 2rem;
            margin-bottom: 1rem;
        }
    }
}
