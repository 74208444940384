@font-face {
    font-family: 'Oleo Script';
    src: url('../assets/fonts/OleoScript-Regular.ttf');
}

:root {

    /* メインカラー */
    --main-color: #3953AC;
    /* ロゴカラー */
    --logo-color: #B8D6E3;
    /* 線ベース */
    --line-base: #353535 1px solid;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;

    /* ヘッダ */
    header {
        height: 5rem;
        left: 0px;
        top: 0px;
        background: var(--main-color);
        display: flex;
        align-items: center;

        .logo {
            padding: {
                left: 2.5rem;
            }

            ;
            /* ロゴフォント */
            font-family: 'Oleo Script';
            font-style: normal;
            font-weight: 400;
            font-size: 3rem;

            /* ロゴカラー */
            color: var(--logo-color);
        }

        .header-content {
            margin-left: auto;
        }
    }

    /* コンテンツ */
    main {
        flex-grow: 1;
        padding: 0.5rem;

        div {
            padding: 0.5rem;
        }
    }

    footer {
        height: 2rem;
        background: var(--main-color);
        display: flex;
        align-items: center;
    }
    
}